import { SnackbarProvider } from 'notistack';
import type { SnackbarProviderProps } from 'notistack';
import { FC } from 'react';

import { MuiSnackbarItem } from '../../common';

type SnackbarProps = Pick<SnackbarProviderProps, 'classes'>;
export const Snackbar: FC<SnackbarProps> = ({ classes }) => (
  <SnackbarProvider
    classes={classes}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    dense={true}
    Components={{
      error: MuiSnackbarItem,
      info: MuiSnackbarItem,
      success: MuiSnackbarItem,
      warning: MuiSnackbarItem,
      default: MuiSnackbarItem,
    }}
  />
);

import { FC } from 'react';

import { IconProps } from './types';

export const RightArrowIcon: FC<IconProps> = (props) => {
  const { width = 48, height = width, color = '#fff', className } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8001 6.4C17.6838 5.73725 18.9374 5.91634 19.6001 6.8L30.7001 21.6C31.7668 23.0222 31.7668 24.9778 30.7001 26.4L19.6001 41.2C18.9374 42.0837 17.6838 42.2627 16.8001 41.6C15.9165 40.9373 15.7374 39.6837 16.4001 38.8L27.0501 24.6C27.3168 24.2444 27.3168 23.7556 27.0501 23.4L16.4001 9.2C15.7374 8.31634 15.9165 7.06274 16.8001 6.4Z"
        fill={color}
      />
    </svg>
  );
};

import { SvgIcon } from '@mui/material';
import { FC } from 'react';

import { IconProps } from './types';

export const SucceedIcon: FC<IconProps> = ({ width = 80, height = 80 }) => {
  return (
    <SvgIcon width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M32.9545 53.006C33.2173 53.3728 33.5637 53.6717 33.9651 53.8779C34.3665 54.0841 34.8113 54.1916 35.2625 54.1916C35.7137 54.1916 36.1585 54.0841 36.5599 53.8779C36.9613 53.6717 37.3077 53.3728 37.5705 53.006L56.3741 26.9346C56.7134 26.4613 56.3741 25.8006 55.7938 25.8006H51.6062C50.6955 25.8006 49.8295 26.2381 49.2938 26.9881L35.267 46.4524L28.9098 37.631C28.3741 36.8899 27.517 36.4435 26.5973 36.4435H22.4098C21.8295 36.4435 21.4902 37.1042 21.8295 37.5774L32.9545 53.006Z" fill="#12BA55"/>
      <path d="M72.8585 4.27734H7.14425C5.5639 4.27734 4.28711 5.55413 4.28711 7.13449V72.8488C4.28711 74.4291 5.5639 75.7059 7.14425 75.7059H72.8585C74.4389 75.7059 75.7157 74.4291 75.7157 72.8488V7.13449C75.7157 5.55413 74.4389 4.27734 72.8585 4.27734ZM69.2871 69.2773H10.7157V10.7059H69.2871V69.2773Z" fill="#12BA55"/>
    </SvgIcon>
  );
};
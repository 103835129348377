import CloseIcon from '@mui/icons-material/Close';
import type { ModalProps as MUIModalProps, SxProps, Theme } from '@mui/material';
import { Box, IconButton, Modal as MUIModal, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import { FC, PropsWithChildren, ReactElement } from 'react';

import styles from './Modal.module.scss';

export type ModalProps = {
  title?: ReactElement;
  open: MUIModalProps['open'];
  onClose: () => void;
  width?: string;
  styleOptions?: SxProps<Theme>;
  variant?: 'default' | 'success' | 'error';
};

const getBackdropClassName = (variant: ModalProps['variant']) => {
  switch (variant) {
    case 'error':
      return styles.backdropError;
    case 'success':
      return styles.backdropSuccess;
    default:
      return '';
  }
};

export const Modal: FC<PropsWithChildren<ModalProps>> = ({
  title,
  open,
  onClose,
  width = '80%',
  styleOptions = {},
  variant = 'default',
  children,
}) => {
  return (
    <MUIModal
      open={open}
      onClose={onClose}
      componentsProps={{
        backdrop: {
          className: getBackdropClassName(variant),
        },
      }}
    >
      <Box
        sx={Object.assign(
          {
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            width: width,
            transform: 'translate(-50%, -50%)',
            background: '#121517',
            borderRadius: '6px',
            padding: '0 1.5rem 0.875rem 1.5rem ',
          },
          styleOptions
        )}
      >
        <IconButton className={styles.modalCloseButton} onClick={onClose} size="large">
          <CloseIcon />
        </IconButton>
        <Typography variant="h2">{title}</Typography>
        {children}
      </Box>
    </MUIModal>
  );
};

import { Alert, Typography } from '@mui/material';
import { SnackbarContent } from 'notistack';
import type { CustomContentProps } from 'notistack';
import { forwardRef } from 'react';

import styles from './MuiSnackbarItem.module.scss';

export const MuiSnackbarItem = forwardRef<HTMLDivElement, CustomContentProps>((props, ref) => (
  <SnackbarContent ref={ref} className={styles.snackBarWrapper}>
    <Alert
      key={props.id}
      severity={props.variant === 'default' ? undefined : props.variant}
      sx={{ width: '100%' }}
      className={[props.className, styles.snackBarAlert].join(' ')}
      icon={false}
    >
      <Typography>{props.message}</Typography>
    </Alert>
  </SnackbarContent>
));
MuiSnackbarItem.displayName = 'MuiSnackbarItem';

import { useRouter } from 'next/router';
import { FC, useMemo, useState } from 'react';

import { SupportedLanguage } from '../../../types/general';
import { FeedbackModal } from '../FeedbackModal/FeedbackModal';
import { ChatIcon } from '../icons';
import { EsFlag, RuFlag, UaFlag, UsFlag } from '../icons/flags';
import { LangModal } from '../LangModal/LangModal';

import styles from './FloatMenu.module.scss';

export const FloatMenu: FC = () => {
  const router = useRouter();
  const [openModal, setOpenModal] = useState(false);
  const [formOpen, setFormOpen] = useState<boolean>(false);

  const toggleLanguagePopup = (): void => {
    setOpenModal(!openModal);
  };

  const handleCloseModal = (): void => {
    setOpenModal(false);
  };

  const toggleFormPopup = (): void => {
    setFormOpen(!formOpen);
  };

  const handleFormClose = (): void => {
    setFormOpen(false);
  };

  const currentFlagComponent = useMemo(() => {
    if (router.locale === SupportedLanguage.Ru) {
      return <RuFlag />;
    }

    if (router.locale === SupportedLanguage.En) {
      return <UsFlag />;
    }

    if (router.locale === SupportedLanguage.Ua) {
      return <UaFlag />;
    }

    if (router.locale === SupportedLanguage.Es) {
      return <EsFlag />;
    }

    return <UsFlag />;
  }, [router.locale]);

  return (
    <>
      <ul className={styles.floatMenu}>
        <li onClick={toggleLanguagePopup}>{currentFlagComponent}</li>
        <li onClick={toggleFormPopup}>
          <ChatIcon />
        </li>
      </ul>

      <FeedbackModal open={formOpen} onClose={handleFormClose} />

      <LangModal open={openModal} onClose={handleCloseModal} />
    </>
  );
};

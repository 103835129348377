import { Box, Container } from '@mui/material';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC } from 'react';

import styles from './Footer.module.scss';

interface FooterList {
  [key: string]: FooterListSection;
}

interface FooterListSection {
  [key: string]: FooterListItem;
}

interface FooterListItem {
  target: string;
  blank?: boolean;
  hidden?: boolean;
}

const menu: FooterList = {
  block_1: {
    accepting_payments: {
      target: '/accepting_payments',
      hidden: true,
    },
    payments: {
      target: '/payments',
      hidden: true,
    },
    transaction_security: {
      target: '/transaction_security',
      hidden: true,
    },
    business_proposals: {
      target: '/business_proposals',
      hidden: true,
    },
  },
  block_2: {
    developers: {
      target: '/dev',
      hidden: true,
    },
    map: {
      target: '/sitemap',
      hidden: true,
    },
    partnership: {
      target: '/partnership',
      hidden: true,
    },
  },
  block_3: {
    about: {
      target: '/about',
      hidden: true,
    },
    solutions: {
      target: '/solutions',
      hidden: true,
    },
    contacts: {
      target: '/contacts',
      hidden: true,
    },
    security: {
      target: '/doc/security-policy-and-refund-of-payments',
    },
    privacy: {
      target: '/doc/privacy',
    },
    terms: {
      target: '/doc/terms-of-use',
    },
    kyc: {
      target: '/doc/kyc',
    },
  },
  block_4: {
    help: {
      target: '/help',
      hidden: true,
    },
    feedback: {
      target: '/feedback',
      hidden: true,
    },
    qa: {
      target: '/qa',
      hidden: true,
    },
  },
  block_6: {
    instagram: {
      target: 'https://instagram.com',
      blank: true,
      hidden: true,
    },
    facebook: {
      target: 'https://facebook.com',
      blank: true,
      hidden: true,
    },
    twitter: {
      target: 'https://twitter.com',
      blank: true,
      hidden: true,
    },
    youtube: {
      target: 'https://youtube.com',
      blank: true,
      hidden: true,
    },
  },
};

export const Footer: FC = () => {
  const { t } = useTranslation('common');
  const { locale } = useRouter();
  const lkBasePath = `/lk/${locale}`;

  const getLinks = (block: string) => {
    return Object.keys(menu[block]).map((key, i) => {
      const item = menu[block][key];
      if (item.hidden) {
        return;
      }

      return (
        <li key={i}>
          <Link locale={item.blank ? false : locale} href={item.target}>
            <a target={item.blank ? '_blank' : '_self'}>{t('footer.links.' + key)}</a>
          </Link>
        </li>
      );
    });
  };

  return (
    <Box className={styles.footerWrapper}>
      <Container maxWidth={'md'} sx={{ margin: '56px auto' }}>
        <a href={`${lkBasePath}/registration`} className={styles.footerButton}>
          {t('login.action')}
        </a>
        <p className={styles.footerLinkGroup}>
          <Link locale={locale} href={menu.block_3.terms.target}>
            <a target="_blank">{t('footer.links.terms')}</a>
          </Link>

          <Link locale={locale} href={menu.block_3.privacy.target}>
            <a target="_blank">{t('footer.links.privacy')}</a>
          </Link>

          <a href={menu.block_3.kyc.target} target="_blank" rel="noreferrer">
            {t('footer.links.kyc')}
          </a>
        </p>
        <p>
          LEAD GATES LTD. UIC: 207064979. Apt. 20, Fl. 6, 14 Miziya Str., Town of Plovdiv, Republic of Bulgaria
          <br />
          support@smart-gates.net
        </p>
        <p>© smartgates, 2022.</p>
      </Container>
    </Box>
  );
};

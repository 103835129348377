import { SvgIcon } from '@mui/material';
import { FC } from 'react';

import { IconProps } from '../types';

export const EsFlag: FC<IconProps> = ({ width = 24, height = 24 }) => {
  return (
    <SvgIcon  width={width} height={height} viewBox={[0, 0, width, height].join(' ')} xmlns="http://www.w3.org/2000/svg">
      <rect width={width} height={height} fill="#c60b1e"/>
      <rect width={width} height={height / 2} y={height/4} fill="#ffc400"/>
    </SvgIcon>
  );
};

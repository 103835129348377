import { Radio } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { FC, useCallback, useState } from 'react';

import { SupportedLanguage } from '../../../types/general';
import styles from '../FloatMenu/FloatMenu.module.scss';
import { EsFlag, RuFlag, UaFlag, UsFlag } from '../icons/flags';
import { ModalDialog } from '../Modal';

type LangModalProps = {
  open: boolean;
  onClose(): void;
};

const supportedLanguageOptions = [
  {
    code: SupportedLanguage.Ru,
    name: 'Русский язык',
    flag: <RuFlag />,
  },
  {
    code: SupportedLanguage.En,
    name: 'English language',
    flag: <UsFlag />,
  },
  {
    code: SupportedLanguage.Ua,
    name: 'Українська мова',
    flag: <UaFlag />,
  },
  {
    code: SupportedLanguage.Es,
    name: 'Español',
    flag: <EsFlag />,
  },
];

export const LangModal: FC<LangModalProps> = (props) => {
  const { open, onClose } = props;

  const router = useRouter();

  const [language, setLanguage] = useState<SupportedLanguage>(router.locale as SupportedLanguage);

  const { t } = useTranslation('common', { keyPrefix: 'user.contract' });

  const handleChangeLanguage = useCallback(async () => {
    const { asPath } = router;

    onClose();

    window.location.href = `/${language}${asPath}`;
  }, [language, onClose, router]);

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      title={t('lang.title')}
      styleOptions={{ width: '25%', minWidth: '250px', maxWidth: '320px' }}
      okButton={{
        text: t('lang.save'),
        onClick: handleChangeLanguage,
      }}
      cancelButton={{
        text: t('lang.cancel'),
        onClick: onClose,
      }}
    >
      <div className={styles.languageContent}>
        <p className={styles.languageSubtitle}>{t('lang.subTitle')}</p>
        <ul className={styles.languageList}>
          {supportedLanguageOptions.map((lang) => (
            <li key={lang.code}>
              <label>
                <Radio checked={language === lang.code} onChange={() => setLanguage(lang.code)} />
                {lang.flag}
                <span>{lang.name}</span>
              </label>
            </li>
          ))}
        </ul>
      </div>
    </ModalDialog>
  );
};

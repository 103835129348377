import { Link as MuiLink } from '@mui/material';
import NextLink from "next/link";
import { FC } from 'react';

import { LogoIcon, LogoTextIcon } from '../icons';

import styles from './Logo.module.scss';

export const Logo: FC = () => {
  return (
    <NextLink href="/" passHref>
        <MuiLink underline="none" className={styles.brandLogo} sx={{marginLeft: { xs: 'auto', md: '0' }}}>
          <span className={styles.brandLogoText}><LogoTextIcon/></span>
          <span className={styles.brandLogoIcon}><LogoIcon /></span>
        </MuiLink>
    </NextLink>
  );
};

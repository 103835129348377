import { createTheme, type CSSInterpolation } from '@mui/material';

import {DownArrowIcon} from "../components/icons";
import variables from '../styles/variables.module.scss';

export const theme = (mode: 'light' | 'dark') =>
  createTheme({
    components: {
      MuiCircularProgress: {
        styleOverrides: {
          root: {

          },
        }
      },

      MuiAccordion: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            margin: '1rem 0',
            borderRadius: '4px',
            position: 'static',
            '& svg': {
              fill: variables.Color_LightGray500,
            },
            '&.Mui-expanded': {
              margin: '1rem 0',
            },
            '& .MuiCollapse-root': {
              backgroundColor: '#121517',
              border: '1px solid ' + variables.Color_Gray500,
              borderRadius: '4px',
              '&.MuiCollapse-entered': {},
              '& .MuiAccordionDetails-root': {
                padding: '0.5rem 1rem',
              },
            },
          } as CSSInterpolation,
        },
      },
      MuiMenu: {
        styleOverrides: {
          root: ({ theme }) => {
            return {
              '& .MuiPaper-root': {background: variables.Color_Gray500},
            };
          },
        },
      },
      MuiModal: {
        styleOverrides: {
          root: {
            '&:not(.MuiMenu-root):not(.MuiPopover-root) .MuiBackdrop-root ': {
              background: 'rgba(36, 41, 45, 0.25)',
              backdropFilter: 'blur(15px)',
            },
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          standardSuccess: {
            backgroundColor: 'rgba(18, 186, 85, 0.25)',
            color: 'white',
          },
          standardError: {
            backgroundColor: 'rgba(238, 50, 31, 0.25)',
            color: 'white',
          },
          standardWarning: {
            backgroundColor: 'rgba(255, 214, 57, 0.25)',
            color: 'white',
          },
          standardInfo: {
            backgroundColor: 'rgba(31,190,238,0.25)',
            color: 'white',
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          sizeMedium: {
            opacity: 0.7,
            svg: {
              width: 16,
              height: 16,
            },
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: ({ theme, ownerState }) => {
            if (ownerState.variant === 'h2') {
              return {
                fontSize: '1.25rem',
                fontWeight: 700,
                paddingTop: '32px',
                paddingBottom: '32px',
                color: theme.palette.text.primary,
              };
            } else {
              return {
                color: theme.palette.text.primary,
              };
            }
          },
        },
      },
      MuiButton: {
        defaultProps: {
          variant: 'contained',
          fullWidth: true,
        },
        styleOverrides: {
          root: {
            color: variables.Color_White500,
            fontSize: '1rem',
            fontWeight: '700',
          },
        },
        variants: [
          {
            props: { variant: 'simple' },
            style: {
              width: 'auto',
              backgroundColor: 'inherit',
            },
          },
        ],
      },
      MuiInputBase: {
        styleOverrides: {
          root: ({ theme, ownerState }) => {
            return {
              border: `1px solid ${variables.Color_Gray300}`,
              marginTop: 12,
              '&.Mui-error input, &.Mui-error .MuiOutlinedInput-input': {
                color: variables.Color_Red500,
              },
              '&.Mui-focused': {
                border: `1px solid ${variables.Color_Green500};`,
              },
            };
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: () => ({
            width: '100%',
          }),
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: () => ({
            display: 'block',
            transform: 'none',
            transition: 'none',
            position: 'static',
            fontSize: '0.75rem',
            '&.Mui-focused': {
              color: variables.Color_LightGray500,
            },
            '&.Mui-focused.Mui-error': {
              color: variables.Color_Red500,
            },
          }),
        },
      },
      MuiTextField: {
        defaultProps: {
          fullWidth: true,
          InputProps: {
            notched: false,
          },
          InputLabelProps: {
            shrink: true,
            error: false,
          },
        },
        styleOverrides: {
          root: ({ theme }) => ({
            display: 'block',
            label: {
              display: 'block',
              transform: 'none',
              transition: 'none',
              position: 'static',
              fontSize: '0.75rem',
              '&.Mui-focused': {
                color: variables.Color_LightGray500,
              },
              '&.Mui-focused.Mui-error': {
                color: variables.Color_Red500,
              },
            },
            '& .MuiInputBase-root': {
              fieldset: {
                display: 'none',
              },
              input: {
                padding: '10px 12px',
                color: variables.Color_Green500,
                '&::placeholder': {
                  color: variables.Color_White500,
                },
                '&:focus::before': {
                  content: '"▶"',
                },
              },
            },
          }),
        },
      },
      MuiSelect: {
        defaultProps: {
          displayEmpty: true,
          IconComponent: (props) => <DownArrowIcon {...props} width={24} height={24} color={'#fff'} />,
        },
        styleOverrides: {
          icon: {
            color: variables.Color_LightGray500,
            right: 0,
            top: 'calc(50% - 0.4em)',
          },
          select: {
            display: 'block',
            padding: '10px 12px',
            color: variables.Color_Green500,
            '& ~ fieldset': {
              borderColor: 'transparent !important',
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            '.MuiButtonBase-root': {
              fontSize: '1.25rem',
              fontWeight: '700',
              '& a': {
                color: variables.Color_LightGray500,
              },
              '&.Mui-selected a': {
                color: variables.Color_Green500,
              },
              '&.Mui-selected svg': {
                display: 'inline-block',
              },

              border: 'none',
            },
          },
          indicator: {
            display: 'none',
          },
        },
      },
      MuiPagination: {
        styleOverrides: {
          root: {
            '& .MuiPaginationItem-root': {
              backgroundColor: 'transparent',
            },
            '& .MuiPaginationItem-root.Mui-selected': {
              backgroundColor: 'transparent',
            },
            '& .MuiPaginationItem-previousNext': {
              backgroundColor: variables.Color_Gray500,
            },
          },
        },
      },
    },

    typography: {
      button: {
        textTransform: 'none',
      },
      fontSize: 14,
      fontFamily: variables.Main_FontFamily,
    },
    palette: {
      mode,
      primary: {
        main: variables.Color_Green500,
      },
      secondary: {
        main: variables.Color_LightGray500,
      },
      border: {
        default: variables.Color_Gray500,
      },
      error: {
        main: variables.Color_Red500,
        dark: variables.Color_Red500,
      },
      success: {
        main: variables.Color_Green500,
        dark: variables.Color_Green500
      },
      warning: {
        main: variables.Color_Yellow500,
        dark: variables.Color_Yellow500,
      },
      common: {
        black: variables.Color_Gray500,
        white: variables.Color_White500,
      },
      background: {
        default: variables.Color_Background,
        defaultButton: variables.Color_Gray500,
      },
      grey: {
        500: variables.Color_Gray500,
      },
      text: {
        primary: variables.Color_White500,
        secondary: variables.Color_LightGray500,
        noTableData: variables.Color_Yellow500,
        error: variables.Color_Red500,
        success: variables.Color_Green500,
        warning: variables.Color_Yellow500,
      },
    },
  });

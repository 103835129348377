import { SvgIcon } from '@mui/material';
import { FC } from 'react';

import { IconProps } from '../types';

export const UsFlag: FC<IconProps> = ({ width = 24, height = 24 }) => {
  return (
    <SvgIcon width={width} height={height} viewBox={[0, 0, width, height].join(' ')} fill="none" stroke="none" strokeWidth="0" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1486_62273)">
          <rect x="1" y="4" width="22" height="16" rx="2" fill="white"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M1 4H10.4286V11.4667H1V4Z" fill="#1A47B8"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M10.4286 4V5.06667H23V4H10.4286ZM10.4286 6.13333V7.2H23V6.13333H10.4286ZM10.4286 8.26667V9.33333H23V8.26667H10.4286ZM10.4286 10.4V11.4667H23V10.4H10.4286ZM1 12.5333V13.6H23V12.5333H1ZM1 14.6667V15.7333H23V14.6667H1ZM1 16.8V17.8667H23V16.8H1ZM1 18.9333V20H23V18.9333H1Z" fill="#F93939"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M2.04785 5.06641V6.13307H3.09547V5.06641H2.04785ZM4.14309 5.06641V6.13307H5.19071V5.06641H4.14309ZM6.23833 5.06641V6.13307H7.28595V5.06641H6.23833ZM8.33357 5.06641V6.13307H9.38119V5.06641H8.33357ZM7.28595 6.13307V7.19974H8.33357V6.13307H7.28595ZM5.19071 6.13307V7.19974H6.23833V6.13307H5.19071ZM3.09547 6.13307V7.19974H4.14309V6.13307H3.09547ZM2.04785 7.19974V8.26641H3.09547V7.19974H2.04785ZM4.14309 7.19974V8.26641H5.19071V7.19974H4.14309ZM6.23833 7.19974V8.26641H7.28595V7.19974H6.23833ZM8.33357 7.19974V8.26641H9.38119V7.19974H8.33357ZM2.04785 9.33307V10.3997H3.09547V9.33307H2.04785ZM4.14309 9.33307V10.3997H5.19071V9.33307H4.14309ZM6.23833 9.33307V10.3997H7.28595V9.33307H6.23833ZM8.33357 9.33307V10.3997H9.38119V9.33307H8.33357ZM7.28595 8.26641V9.33307H8.33357V8.26641H7.28595ZM5.19071 8.26641V9.33307H6.23833V8.26641H5.19071ZM3.09547 8.26641V9.33307H4.14309V8.26641H3.09547Z" fill="white"/>
        </g>
        <defs>
          <clipPath id="clip0_1486_62273">
            <rect x="1" y="4" width="22" height="16" rx="2" fill="white"/>
          </clipPath>
        </defs>
    </SvgIcon>
  );
};

import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { FC, useState } from 'react';

import styles from './Navigation.module.scss';

interface MenuItem {
  target: string
  title: string
}

export const Navigation: FC = () => {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const { locale } = useRouter();
  const { t } = useTranslation('common');
  const lkBasePath = `/lk/${locale}`;

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const pages: MenuItem[] = [
    {
      target: '#product',
      title: t('navigation.product'),
    },
    {
      target: '#resource',
      title: t('navigation.resource'),
    },
    {
      target: '#support',
      title: t('navigation.support'),
    }
  ];


  return (
    <>
      <Box sx={{display: { xs: 'flex', md: 'none' } }}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenNavMenu}
          color="inherit"
          style={{
            marginLeft: "auto"
          }}
        >
          {Boolean(anchorElNav) ? <CloseIcon /> : <MenuIcon />}
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorElNav}
          MenuListProps={{
            "aria-labelledby": "basic-button",
            sx: { padding: '0', height: 'calc(100vh - 164px)' }
          }}
          PaperProps={{
            style: {
              position: "absolute",
              width: '100%',
              borderRadius: 0,
              top: '64px',
              padding: '2rem',
              height: 'calc(100% - 64px)',
              backgroundColor: '#121517',
            }
          }}
          keepMounted
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
          sx={{
            display: { xs: 'block', md: 'none' },
          }}
        >
          {pages.map((item, index) => (
            <MenuItem key={index} onClick={handleCloseNavMenu} sx={{display: "flex", padding: 0}}>
              <a key={index} onClick={handleCloseNavMenu} href={`${lkBasePath}/account/login`} className={styles.navigationItemLink}>
                {item.title}
              </a>
            </MenuItem>
          ))}
          <MenuItem onClick={handleCloseNavMenu} sx={{display: "flex", position: 'absolute', bottom: '2rem'}}>
            <a href={`${lkBasePath}/account/login`} className={styles.navigationItemLink}>
              {t('login.login')}
            </a>

            <a href={`${lkBasePath}/registration`} className={styles.navigationItemButton}>
              {t('login.action')}
            </a>
          </MenuItem>

        </Menu>
      </Box>

      <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} className={styles.navigationRoot}>
        {pages.map((item, index) => (
          <a key={index} onClick={handleCloseNavMenu} href={`${lkBasePath}/account/login`} className={styles.navigationItemLink}>
            {item.title}
          </a>
        ))}
      </Box>
      <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} className={styles.navigationRootEnd}>
        <a href={`${lkBasePath}/account/login`} className={styles.navigationItemLink}>
          {t('login.login')}
        </a>

        <a href={`${lkBasePath}/registration`} className={styles.navigationItemButton}>
          {t('login.action')}
        </a>
      </Box>

    </>
  );
};

import { SvgIcon } from '@mui/material';
import { FC } from 'react';

import { IconProps } from '../types';

export const RuFlag: FC<IconProps> = ({ width = 24, height = 24 }) => {
  return (
    <SvgIcon width={width} height={height} viewBox={[0, 0, width, height].join(' ')} fill="none" stroke="none" strokeWidth="0" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1489_66055)">
          <rect x="1" y="4" width="22" height="16" rx="2" fill="#1A47B8"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M1 14.667H23V20.0003H1V14.667Z" fill="#F93939"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M1 4H23V9.33333H1V4Z" fill="white"/>
        </g>
        <defs>
          <clipPath id="clip0_1489_66055">
            <rect x="1" y="4" width="22" height="16" rx="2" fill="white"/>
          </clipPath>
        </defs>

    </SvgIcon>
  );
};

import { SvgIcon } from '@mui/material';
import { FC } from 'react';

import { IconProps } from './types';

export const InfoIcon: FC<IconProps> = ({ width = 80, height = 80 }) => {
  return (
    <SvgIcon width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M40 9.52288C23.1685 9.52288 9.52386 23.1675 9.52386 39.9991C9.52386 56.8306 23.1685 70.4753 40 70.4753C56.8316 70.4753 70.4762 56.8306 70.4762 39.9991C70.4762 23.1675 56.8316 9.52288 40 9.52288ZM3.80957 39.9991C3.80957 20.0116 20.0126 3.80859 40 3.80859C59.9875 3.80859 76.1905 20.0116 76.1905 39.9991C76.1905 59.9865 59.9875 76.1896 40 76.1896C20.0126 76.1896 3.80957 59.9865 3.80957 39.9991Z" fill="#16DB65"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M40 23.8086C41.5779 23.8086 42.8571 25.0878 42.8571 26.6657V39.9991C42.8571 41.577 41.5779 42.8562 40 42.8562C38.422 42.8562 37.1428 41.577 37.1428 39.9991V26.6657C37.1428 25.0878 38.422 23.8086 40 23.8086Z" fill="#16DB65"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M37.1428 53.3337C37.1428 51.7557 38.422 50.4766 40 50.4766H40.0333C41.6113 50.4766 42.8904 51.7557 42.8904 53.3337C42.8904 54.9117 41.6113 56.1908 40.0333 56.1908H40C38.422 56.1908 37.1428 54.9117 37.1428 53.3337Z" fill="#16DB65"/>
    </SvgIcon>

  );
};
import { ApolloProvider } from '@apollo/client';
import { CssBaseline, ThemeProvider, useMediaQuery } from '@mui/material';
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import en from 'dayjs/locale/en';
import ru from 'dayjs/locale/ru';
import { NextPage } from 'next';
import { appWithTranslation, i18n } from 'next-i18next';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import Script from "next/script";
import { ReactElement, ReactNode, FC } from 'react';

import { MainLayout } from "../components/layout/MainLayout";
import { useApollo } from '../lib/apollo-client';
import { theme } from '../theme/theme';

import '../../src/styles/globals.scss';

export type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
  /** Если true то пользователь должен быть авторизован */
  authRequired?: boolean;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const getDefaultLayout: NonNullable<NextPageWithLayout['getLayout']> = (page) => <MainLayout>{page}</MainLayout>;

const MyApp: FC<AppPropsWithLayout> = ({ Component, pageProps }) => {
  const { locale } = useRouter();
  const getLayout = Component.getLayout ?? getDefaultLayout;
  const apolloClient = useApollo(pageProps);

  return (
    <>
      {process.env.IS_PROD ? (
        <>
          <Script
            id={"googleTagManager"}
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-PPL9QM8');
            `,
            }}
          />
        </>
      ) : (
        <>
          <Script
            id={"googleTagManager"}
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-M9ZR99R');
            `,
            }}
          />

          <Script async src="https://www.googletagmanager.com/gtag/js?id=G-MP2N873P2L"></Script>
          <Script
            id={"googleTag"}
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-MP2N873P2L');`,
            }}
          />
        </>
      )}

      <ApolloProvider client={apolloClient}>
      <ThemeProvider theme={theme('dark')}>
        <CssBaseline />
        <LocalizationProvider adapterLocale={locale === 'ru' ? ru : en} dateAdapter={AdapterDayjs}>
        {getLayout(<Component {...pageProps} />)}
        </LocalizationProvider>
      </ThemeProvider>
      </ApolloProvider>
    </>
  );
};

/* === HOT RELOAD FOR next-i18next === */
if (process.env.NODE_ENV !== 'production') {
  if (typeof window !== 'undefined') {
    const { applyClientHMR } = require('i18next-hmr/client');
    applyClientHMR(() => i18n);
  } else {
    const { applyServerHMR } = require('i18next-hmr/server');
    applyServerHMR(() => i18n);
  }
}
/* ===================================== */

export default appWithTranslation(MyApp);

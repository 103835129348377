import { Container } from '@mui/material';
import { useTheme } from "@mui/material/styles";
import { useRouter } from "next/router";
import { FC, PropsWithChildren } from 'react';

import { FloatMenu } from "../../FloatMenu";
import { Footer } from "../../Footer";
import { HeaderBar } from "../../HeaderBar";
import {Snackbar} from "../Snackbar";

import styles from './MainLayout.module.scss'


type MainLayoutProps = {
  /** Дополнительный класс для содержимого страницы */
  pageContentClassName?: string
};

export const MainLayout: FC<PropsWithChildren<MainLayoutProps>> = ({ pageContentClassName, children }) => {
  const theme = useTheme();
  const route = useRouter();
  return (
    <Container disableGutters maxWidth={false} sx={{ display: 'flex' }} data-theme={theme.palette.mode}>
      <div className={`page-content page-content-noSidebar ${pageContentClassName ?? ''}`}>
        <Snackbar classes={{ containerRoot: styles.snackbarContainerRoot, root: styles.snackbarRoot }} />

        <HeaderBar />
        <Container disableGutters maxWidth={false} className="page-container">
          {children}
        </Container>
        <FloatMenu />
        <Footer />
      </div>
    </Container>
  );
};

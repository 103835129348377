import { SvgIcon } from '@mui/material';
import { FC } from 'react';

import { IconProps } from './types';

export const CheckIcon: FC<IconProps> = ({ width = 223, height = 152 }) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M30.2761 4.39052C30.7968 4.91122 30.7968 5.75544 30.2761 6.27614L16.9428 19.6095C16.4221 20.1302 15.5779 20.1302 15.0572 19.6095L11.0572 15.6095C10.5365 15.0888 10.5365 14.2446 11.0572 13.7239C11.5779 13.2032 12.4221 13.2032 12.9428 13.7239L16 16.781L28.3905 4.39052C28.9112 3.86983 29.7554 3.86983 30.2761 4.39052Z"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M6.66667 5.33464C6.31305 5.33464 5.97391 5.47511 5.72386 5.72516C5.47381 5.97521 5.33334 6.31435 5.33334 6.66797V25.3346C5.33334 25.6883 5.47381 26.0274 5.72386 26.2774C5.97391 26.5275 6.31305 26.668 6.66667 26.668H25.3333C25.687 26.668 26.0261 26.5275 26.2761 26.2774C26.5262 26.0274 26.6667 25.6883 26.6667 25.3346V16.0013C26.6667 15.2649 27.2636 14.668 28 14.668C28.7364 14.668 29.3333 15.2649 29.3333 16.0013V25.3346C29.3333 26.3955 28.9119 27.4129 28.1618 28.1631C27.4116 28.9132 26.3942 29.3346 25.3333 29.3346H6.66667C5.60581 29.3346 4.58839 28.9132 3.83824 28.1631C3.0881 27.4129 2.66667 26.3955 2.66667 25.3346V6.66797C2.66667 5.6071 3.0881 4.58969 3.83824 3.83954C4.58839 3.0894 5.60581 2.66797 6.66667 2.66797H21.3333C22.0697 2.66797 22.6667 3.26492 22.6667 4.0013C22.6667 4.73768 22.0697 5.33464 21.3333 5.33464H6.66667Z"/>
    </svg>




  );
};



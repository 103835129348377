import { SvgIcon } from '@mui/material';
import { FC } from 'react';

import { IconProps } from './types';

export const ScrollDownIcon: FC<IconProps> = ({ className, width = 28, height = 20, color }) => {
  return (
    <SvgIcon width={width} height={height} viewBox={[0, 0, width, height].join(' ')} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M27.2853 0.857422H24.6068C24.4246 0.857422 24.2532 0.946708 24.146 1.09314L13.9996 15.0789L3.85319 1.09314C3.74604 0.946708 3.57462 0.857422 3.39247 0.857422H0.713901C0.481758 0.857422 0.346044 1.12171 0.481758 1.31099L13.0746 18.6717C13.5318 19.3003 14.4675 19.3003 14.921 18.6717L27.5139 1.31099C27.6532 1.12171 27.5175 0.857422 27.2853 0.857422V0.857422Z" fill="#16DB65"/>
    </SvgIcon>
  );
};

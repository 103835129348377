import { SvgIcon } from '@mui/material';
import { FC } from 'react';

import { IconProps } from './types';

export const RepeatIcon: FC<IconProps> = ({ width = 223, height = 152 }) => {
  return (
    <SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3086_106618)">
        <path fillRule="evenodd" clipRule="evenodd" d="M21.7239 0.390524C22.2446 -0.130175 23.0888 -0.130175 23.6095 0.390524L28.9428 5.72386C29.4635 6.24456 29.4635 7.08878 28.9428 7.60948L23.6095 12.9428C23.0888 13.4635 22.2446 13.4635 21.7239 12.9428C21.2032 12.4221 21.2032 11.5779 21.7239 11.0572L26.1144 6.66667L21.7239 2.27614C21.2032 1.75544 21.2032 0.911223 21.7239 0.390524Z"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M9.33333 7.9987C8.27247 7.9987 7.25505 8.42012 6.50491 9.17027C5.75476 9.92042 5.33333 10.9378 5.33333 11.9987V14.6654C5.33333 15.4017 4.73638 15.9987 4 15.9987C3.26362 15.9987 2.66667 15.4017 2.66667 14.6654V11.9987C2.66667 10.2306 3.36904 8.53489 4.61929 7.28465C5.86953 6.03441 7.56522 5.33203 9.33333 5.33203H28C28.7364 5.33203 29.3333 5.92898 29.3333 6.66536C29.3333 7.40174 28.7364 7.9987 28 7.9987H9.33333Z"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M10.2761 19.0585C10.7968 19.5792 10.7968 20.4234 10.2761 20.9441L5.88562 25.3346L10.2761 29.7252C10.7968 30.2459 10.7968 31.0901 10.2761 31.6108C9.75544 32.1315 8.91122 32.1315 8.39052 31.6108L3.05719 26.2774C2.53649 25.7567 2.53649 24.9125 3.05719 24.3918L8.39052 19.0585C8.91122 18.5378 9.75544 18.5378 10.2761 19.0585Z"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M28 16C28.7364 16 29.3333 16.597 29.3333 17.3333V20C29.3333 21.7681 28.631 23.4638 27.3807 24.714C26.1305 25.9643 24.4348 26.6667 22.6667 26.6667H4C3.26362 26.6667 2.66667 26.0697 2.66667 25.3333C2.66667 24.597 3.26362 24 4 24H22.6667C23.7275 24 24.7449 23.5786 25.4951 22.8284C26.2452 22.0783 26.6667 21.0609 26.6667 20V17.3333C26.6667 16.597 27.2636 16 28 16Z"/>
      </g>
      <defs>
        <clipPath id="clip0_3086_106618">
          <rect width="32" height="32" fill="white"/>
        </clipPath>
      </defs>
    </SvgIcon>



  );
};



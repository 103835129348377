import { SvgIcon } from '@mui/material';
import { FC } from 'react';

import { IconProps } from './types';

export const CreditCardIcon: FC<IconProps> = ({ width = 223, height = 152 }) => {
  return (
    <SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M4 6.66667C3.26362 6.66667 2.66667 7.26362 2.66667 8V24C2.66667 24.7364 3.26362 25.3333 4 25.3333H28C28.7364 25.3333 29.3333 24.7364 29.3333 24V8C29.3333 7.26362 28.7364 6.66667 28 6.66667H4ZM0 8C0 5.79086 1.79086 4 4 4H28C30.2091 4 32 5.79086 32 8V24C32 26.2091 30.2091 28 28 28H4C1.79086 28 0 26.2091 0 24V8Z"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M0 13.3333C0 12.597 0.596954 12 1.33333 12H30.6667C31.403 12 32 12.597 32 13.3333C32 14.0697 31.403 14.6667 30.6667 14.6667H1.33333C0.596954 14.6667 0 14.0697 0 13.3333Z"/>
    </SvgIcon>



  );
};



import { SvgIcon } from '@mui/material';
import { FC } from 'react';

import { IconProps } from './types';

export const CopyIcon: FC<IconProps> = ({ width = 16, height = 18 }) => {
  return (
    <SvgIcon width={width} height={height} viewBox={[0, 0, width, height].join(' ')} fill="none" stroke="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.4282 0H3.66037C3.57197 0 3.49965 0.0723214 3.49965 0.160714V1.28571C3.49965 1.37411 3.57197 1.44643 3.66037 1.44643H13.6247V15.2679C13.6247 15.3562 13.697 15.4286 13.7854 15.4286H14.9104C14.9988 15.4286 15.0711 15.3562 15.0711 15.2679V0.642857C15.0711 0.287277 14.7838 0 14.4282 0ZM11.8568 2.57143H1.57108C1.2155 2.57143 0.928223 2.85871 0.928223 3.21429V13.8757C0.928223 14.0464 0.996526 14.2092 1.11706 14.3297L4.59854 17.8112C4.64273 17.8554 4.69296 17.8915 4.7472 17.9217V17.9598H4.83157C4.90188 17.9859 4.97621 18 5.05255 18H11.8568C12.2124 18 12.4997 17.7127 12.4997 17.3571V3.21429C12.4997 2.85871 12.2124 2.57143 11.8568 2.57143ZM4.74519 15.9147L3.0155 14.183H4.74519V15.9147ZM11.0532 16.5536H6.0309V13.7009C6.0309 13.2569 5.6713 12.8973 5.22733 12.8973H2.37465V4.01786H11.0532V16.5536Z" />
    </SvgIcon>
  );
};

import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import FeedbackForm from '../common/FeedbackForm/FeedbackForm';
import { ModalDialog } from '../Modal';

type FeedbackModalProps = {
  open: boolean;
  onClose(): void;
};

export const FeedbackModal: FC<FeedbackModalProps> = (props) => {
  const { open, onClose } = props;

  const { t } = useTranslation('common', { keyPrefix: 'forms.feedback' });

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      title={t('title')}
      styleOptions={{ width: '25%', minWidth: '250px', maxWidth: '320px' }}
      okButton={{
        text: t('buttons.ok'),
        onClick: onClose,
      }}
    >
      <FeedbackForm />
    </ModalDialog>
  );
};

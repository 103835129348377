import { Toolbar, AppBar, useMediaQuery } from '@mui/material';
import {useTranslation} from "next-i18next";
import {useRouter} from "next/router";
import { FC } from 'react';

import { Logo } from "../Logo";
import { Navigation } from "../Navigation";

import styles from './HeaderBar.module.scss';

type HeaderBarProps = {};

export const HeaderBar: FC<HeaderBarProps> = () => {
  const isSmallSize = useMediaQuery('(max-width:960px)');
  const { locale } = useRouter();
  const { t } = useTranslation('common');
  const lkBasePath = `/lk/${locale}`;

  return (
    <AppBar
      position="sticky"
      className={styles.headerLayout}

    >
      <Toolbar>
        {isSmallSize ? (
          <>
            <Navigation />
            <a href={`${lkBasePath}/registration`} className={styles.navigationItemButton}>
              {t('login.action')}
            </a>
            <Logo />
          </>
        ) : (
          <>
            <Logo />
            <Navigation />
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

import { useTranslation } from "next-i18next";
import React from 'react';

import styles from './Feedbackform.module.scss';

const FeedbackForm = () => {
  const { t } = useTranslation('common', { keyPrefix: 'forms.feedback' });
  return (
    <div className={styles.text} dangerouslySetInnerHTML={{
      __html: t('text')
    }}>

    </div>
  );
};

export default FeedbackForm;
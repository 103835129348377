import { Button } from '@mui/material';
import { useTheme } from '@mui/system';
import { FC, PropsWithChildren } from 'react';

import { Modal, ModalProps } from './Modal';

type ModalDialogProps = ModalProps & {
  okButton: {
    text: string;
    onClick: () => void;
  };
  cancelButton?: {
    text: string;
    onClick: () => void;
  };
};

export const ModalDialog: FC<PropsWithChildren<ModalDialogProps>> = ({
  okButton,
  cancelButton,
  children,
  variant,
  ...props
}) => {
  return (
    <Modal {...props} variant={variant}>
      <div>{children}</div>
      <Button
        type="submit"
        onClick={okButton.onClick}
        sx={{ marginTop: '2rem' }}
        color={variant === 'default' ? 'success' : variant}
      >
        {okButton.text}
      </Button>

      {cancelButton && (
        <Button variant={'text'} sx={{ marginTop: '1rem' }} onClick={cancelButton.onClick}>
          {cancelButton.text}
        </Button>
      )}
    </Modal>
  );
};

import { SvgIcon } from '@mui/material';
import { FC } from 'react';

import { IconProps } from './types';

export const DownArrowIcon: FC<IconProps> = ({ className, width = 18, height = 18, color }) => {
  return (
    <SvgIcon
      className={className}
      style={{
        transform: className?.includes('MuiSelect-iconOpen') ? 'rotate(180deg) translate(6px,6px)' : undefined,
      }}
      viewBox={[0, 0, width, height].join(' ')}
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.1293 7.11873H11.3049C11.1256 7.11873 10.955 7.20486 10.8496 7.35076L9.00036 9.90662L7.15114 7.35076C7.04567 7.20486 6.87692 7.11873 6.69587 7.11873H5.87145C5.7572 7.11873 5.6904 7.24881 5.7572 7.34197L8.8861 11.6662C8.94235 11.7435 9.05661 11.7435 9.11286 11.6662L12.2418 7.34197C12.3103 7.24881 12.2435 7.11873 12.1293 7.11873Z" />
      <path d="M9 1.125C4.65117 1.125 1.125 4.65117 1.125 9C1.125 13.3488 4.65117 16.875 9 16.875C13.3488 16.875 16.875 13.3488 16.875 9C16.875 4.65117 13.3488 1.125 9 1.125ZM9 15.5391C5.38945 15.5391 2.46094 12.6105 2.46094 9C2.46094 5.38945 5.38945 2.46094 9 2.46094C12.6105 2.46094 15.5391 5.38945 15.5391 9C15.5391 12.6105 12.6105 15.5391 9 15.5391Z" />
    </SvgIcon>
  );
};
